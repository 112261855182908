export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },

    {
      displayName: 'menu.assets',
      permissions: ['admin', 'general', 'assets'],
      meta: {
        icon: 'apartment',
      },
      children: [
        {
          name: 'company',
          displayName: 'menu.company',
          permissions: ['admin', 'company'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'property',
          displayName: 'menu.property',
          permissions: ['admin', 'property'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'rental-unit',
          displayName: 'menu.rentalunits',
          permissions: ['admin', 'rentalunit'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'parking-lot',
          displayName: 'menu.parkinglots',
          permissions: ['admin', 'parkinglot'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'cellar',
          displayName: 'menu.cellar',
          permissions: ['admin', 'cellar'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'apartment-type',
          displayName: 'menu.apartment_type',
          permissions: ['admin', 'apartment_type'],
          meta: {
            icon: 'fa-house',
          },
        },

        {
          name: 'customer',
          displayName: 'menu.customer',
          permissions: ['admin', 'customer'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
      ]
    },
    
    {
      displayName: 'menu.tenancy_management',
      permissions: ['admin', 'general', 'tenancy-management'],
      meta: {
        icon: 'bedroom_parent',
      },
      children: [
        {
          name: 'tenancy',
          displayName: 'menu.tenancy',
          permissions: ['admin', 'tenancy'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'tenancy-business',
          displayName: 'menu.tenancy_business',
          permissions: ['admin', 'tenancy'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'rental-contract',
          displayName: 'menu.rentalcontract',
          permissions: ['admin', 'tenancy'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'tenant',
          displayName: 'menu.tenant',
          permissions: ['admin', 'tenant'],
          meta: {
            icon: 'fa-house',
          },
        },
      ]
    },
    
    {
      displayName: 'menu.renting',
      permissions: ['admin', 'tenant', 'tenant-suggestions'],
      meta: {
        icon: 'group',
      },
      children: [
        {
          name: 'tenant-suggestion-overview',
          displayName: 'menu.tenant_suggestion_overview',
          permissions: ['admin', 'tenant', 'tenant-suggestions'],
        },
        {
          name: 'tenant-suggestion-add',
          displayName: 'menu.tenant_suggestion_add',
          permissions: ['admin', 'tenant', 'tenant-suggestions'],
        },
      ]
    },

    {
      displayName: 'menu.appointment',
      permissions: ['admin', 'appointment'],
      meta: {
        icon: 'free_cancellation',
      },
      children: [
        {
          name: 'appointment/service',
          displayName: 'menu.appointment_service',
          permissions: ['admin', 'appointment'],
        },
        {
          name: 'appointment/user',
          displayName: 'menu.appointment_user',
          permissions: ['admin', 'appointment'],
        },
      ]
    },

    {
      displayName: 'menu.accounting',
      permissions: ['admin', 'general', 'accounting'],
      meta: {
        icon: 'calculate',
      },
      children: [
        {
          name: 'receivable',
          displayName: 'menu.receivable',
          permissions: ['admin', 'receivable'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'invoice',
          displayName: 'menu.invoice',
          permissions: ['admin', 'invoice'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'bank-transfers',
          displayName: 'menu.bank_transfers',
          permissions: ['admin', 'banktransfers'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'export',
          displayName: 'menu.export',
          permissions: ['admin', 'accounting-export'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },

        {
          name: 'purchase-price-request',
          displayName: 'menu.purchase_price_request',
          permissions: ['admin', 'accounting-purchase-price-request'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
      ]
    },

    {
      displayName: 'menu.reports',
      permissions: ['admin', 'reports'],
      meta: {
        icon: 'flag',
      },
      children: [
        {
          name: 'report-general',
          displayName: 'menu.general',
          permissions: ['admin', 'reports-general'],
        },
        {
          name: 'report-apartments',
          displayName: 'menu.apartments',
          permissions: ['admin', 'reports-apartments'],
        },
        {
          name: 'report-rent-arrears',
          displayName: 'menu.rent_arrears',
          permissions: ['admin', 'reports-rent-arrears'],
        },
        {
          name: 'report-renting',
          displayName: 'menu.renting',
          permissions: ['admin', 'reports-renting'],
        },
        
        {
          name: 'report-weekly',
          displayName: 'menu.weekly_report',
          permissions: ['admin', 'reports-weekly'],
        },
        {
          name: 'report-renewal',
          displayName: 'menu.renewal_report',
          permissions: ['admin', 'reports-renewal'],
        },

        {
          name: 'report-receivables',
          displayName: 'menu.receivable',
          permissions: ['admin', 'reports-receivables'],
        },
        {
          name: 'report-rental-contracts',
          displayName: 'menu.rentalcontract',
          permissions: ['admin', 'reports-rental-contracts'],
        },
        {
          name: 'report-tenants',
          displayName: 'menu.tenant',
          permissions: ['admin', 'reports-tenants'],
        },
        {
          name: 'report-analytics',
          displayName: 'menu.analytics',
          permissions: ['admin', 'reports-analytics'],
        },
        {
          name: 'report-mailer',
          displayName: 'menu.mailer',
          permissions: ['admin', 'mailer'],
        },
        {
          name: 'report-map',
          displayName: 'menu.report_map',
          permissions: ['admin', 'map'],
        },
        {
          name: 'datev',
          displayName: 'menu.datev',
          permissions: ['admin', 'datev'],
        },
      ]
    },
    
    {
      displayName: 'menu.import',
      permissions: ['admin', 'property'],
      meta: {
        icon: 'backup',
      },
      children: [
        {
          name: 'import-bank-transfer',
          displayName: 'menu.bank_transfer',
          permissions: ['admin', 'import'],
          meta: {
            icon: 'fa-house',
          },
        },
      ]
    },

    {
      displayName: 'menu.handover',
      permissions: ['admin', 'handover'],
      meta: {
        icon: 'sync_alt',
      },
      children: [
        {
          name: 'handover-protocol',
          displayName: 'menu.handover_protocol',
          permissions: ['admin', 'handover'],
          meta: {
            icon: 'fa-house',
          },
        },
      ]
    },
    {
      displayName: 'menu.scouting',
      permissions: ['admin', 'scouting'],
      meta: {
        icon: 'preview',
      },
      children: [
        {
          name: 'property-scouting-service-provider',
          displayName: 'menu.property_scouting_service_provider',
          permissions: ['admin', 'scouting'],
          meta: {
            icon: 'fa-house',
          },
        },
        {
          name: 'property-scouting-condition-assessment',
          displayName: 'menu.property_scouting_condition_assessment',
          permissions: ['admin', 'scouting'],
          meta: {
            icon: 'fa-house',
          },
        },
      ]
    },

    {
      name: 'viewing',
      displayName: 'menu.viewing',
      permissions: ['admin', 'viewing'],
      meta: {
        icon: 'visibility',
      },
      children: [
        {
          name: 'viewing-apartment-overview',
          displayName: 'menu.overview_appartments',
          permissions: ['admin', 'viewing'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'viewing-apartment-add',
          displayName: 'menu.viewing_appartments',
          permissions: ['admin', 'viewing'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'viewing-handover-protocol',
          displayName: 'menu.viewing_handover',
          permissions: ['admin', 'viewing'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

    {
      name: 'settings',
      displayName: 'menu.settings',
      permissions: ['admin', 'settings'],
      meta: {
        icon: 'settings',
      },
    },

    {
      name: 'analytics',
      displayName: 'menu.analytics',
      permissions: ['admin', 'analytics'],
      meta: {
        icon: 'query_stats',
      },
      children: [
        {
          name: 'analytics/visitor',
          displayName: 'menu.analytics_visitor',
          permissions: ['admin', 'analytics'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

    {
      displayName: 'menu.data',
      permissions: ['admin', 'database'],
      meta: {
        icon: 'folder_open',
      },
      children: [
        {
          name: 'cpi',
          displayName: 'menu.cpi',
          permissions: ['admin', 'cpi'],
          meta: {
            icon: 'fa-house',
          },
        },
      ]
    },
    
    {
      displayName: 'menu.admin',
      permissions: ['admin'],
      meta: {
        icon: 'admin_panel_settings',
      },
      children: [
        {
          name: 'admin/user',
          displayName: 'menu.user',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'admin/settings',
          displayName: 'menu.settings',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

  ] as INavigationRoute[],
}
